import React from "react";
import { graphql } from "gatsby";
import BusinessCase from "./case-page";

export default (props) => <BusinessCase {...props} />;

export const caseQuery = graphql`
  query CasePageENByID($id: String!) {
    case: markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        langKey
      }
      frontmatter {
        title
        description
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 400, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        clientlogo {
          childImageSharp {
            fluid(maxWidth: 400, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
